/* eslint-disable */
import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import {
	Box,
	Button,
	Container,
	Grid,
	makeStyles,
	Typography
} from '@material-ui/core'

import MyTheme from '../utils/myTheme'

const theme = MyTheme

const useStyles = makeStyles(theme => ({
	button: { marginBottom: theme.spacing(4) }
}))

const CookItaly = ({ data }) => {
	const classes = useStyles()
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant='h3' align='center'>
						Cook Italy Tours
					</Typography>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Img fluid={data.castle.childImageSharp.fluid} />
				</Grid>

				<Grid item xs={12} sm={8}>
					<Typography variant='body1' color='textPrimary' gutterBottom>
						Join Chef Dana Reinhardt in exploring the incredible bounty of
						Lazio, Italy. The farm is covered with olive trees and vineyards and
						boasts a beautiful pool and vistas. This beautiful area is also home
						to many ancient Etruscan necropolis and ruins.
						<p>
							We will harvest seasonal produce and use meat from the heritage
							breed cattle, pigs and chickens located directly on or in
							proximity to the property. We will learn to make bread in the wood
							fire oven; master the tricks of hand-made pasta and forage for
							wild herbs and mushrooms in the surrounding woodlands.
						</p>
					</Typography>
					<center>
						<Img
							fluid={data.logo.childImageSharp.fluid}
							style={{ width: '25%', marginBottom: 20 }}
						/>
						<Button
							href='http://cookitalytours.com/'
							variant='outlined'
							size='large'
							className={classes.button}
						>
							go to website
						</Button>
					</center>
				</Grid>
			</Grid>
		</>
	)
}

export default CookItaly

export const query = graphql`
	query {
		castle: file(name: { eq: "cookItaly-table" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 500) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		logo: file(name: { eq: "cookItaly-logo" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 500) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`
